export const packageCategories = [
    { value: 'dayout', label: 'Day Out' },
    { value: 'dayout_with_room', label: 'Day Out with Room' },
    { value: 'overnight', label: 'Overnight' },
    { value: 'high_tea', label: 'High Tea' },
    { value: 'lunch', label: 'Lunch' },
    { value: 'dinner', label: 'Dinner' }
];

export const pricingTypes = {
    per_person: 'Per Person',
    per_room: 'Per Room'
};

export const allAmenities = ["pool", "ac", "wifi", "shower", "deluxe", "evening tea", "welcome drink", "beach", "sea", "city", "garden", "lake", "lunch", "kayak", "smoking", "non-smoking", "tv", "toiletries", "bathtub", "locker", "buffet", "alacarte", "room only"];
export const restrictedAmenities = ["ac", "wifi"];

export const toSentenceCase = (str) => {
    return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
};
