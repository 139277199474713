import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHotelDetails } from '../../state/hotel/actions';

function HotelsTable({ hotels }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <div className="overflow-x-auto w-full">
            <table className="min-w-full bg-white border border-gray-300 shadow-md rounded-lg overflow-hidden">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="px-2 md:px-4 py-2 border text-left">Name</th>
                        <th className="px-2 md:px-4 py-2 border text-left">Type</th>
                        <th className="px-2 md:px-4 py-2 border text-left">City</th>
                        <th className="px-2 md:px-4 py-2 border text-left">District</th>
                        <th className="px-2 md:px-4 py-2 border text-left">Contact</th>
                        <th className="px-2 md:px-4 py-2 border text-left">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {hotels.length > 0 ? (
                        hotels.map((hotel) => (
                            <tr key={hotel._id} className="hover:bg-gray-100 transition duration-200">
                                <td className="px-2 md:px-4 py-2 border">{hotel.name}</td>
                                <td className="px-2 md:px-4 py-2 border">{hotel.type}</td>
                                <td className="px-2 md:px-4 py-2 border">{hotel.location.city}</td>
                                <td className="px-2 md:px-4 py-2 border">{hotel.location.district}</td>
                                <td className="px-2 md:px-4 py-2 border">{hotel.contactDetails.email}</td>
                                <td className="px-2 md:px-4 py-2 border">
                                    <button
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 md:py-2 md:px-4 rounded transition duration-200"
                                        onClick={() => {
                                            navigate(`/p/${hotel._id}`);
                                            dispatch(setHotelDetails(hotel));
                                        }}
                                    >
                                        View
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className="px-2 md:px-4 py-2 border text-center">
                                No hotels available.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default HotelsTable;