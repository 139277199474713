// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/reducer';
import hotelReducer from './hotel/reducer';

export default configureStore({
  reducer: {
    user: userReducer,
    hotel: hotelReducer,
  },
});