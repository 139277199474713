import React, { useEffect, useRef } from 'react';
import 'tailwindcss/tailwind.css';

const FindPlaceID = ({ setPlaceId, setLat, setLng, setAddress, setName, setRating, setUserRatingsTotal }) => {
  const mapRef = useRef(null);
  const inputRef = useRef(null);
  const infowindowRef = useRef(null);
  const placeNameRef = useRef(null);
  const placeIdRef = useRef(null);
  const placeAddressRef = useRef(null);

  useEffect(() => {
    const provinceMappings = {
      CP: 'Central',
      WP: 'Western',
      SP: 'Southern',
      EP: 'Eastern',
      NP: 'Northern',
      SG: 'Sabaragamuwa',
      UP: 'Uva',
      NW: 'North Western',
      NC: 'North Central',
    };

    const loadScript = (url, callback) => {
      if (document.querySelector(`script[src="${url}"]`)) {
        if (window.google) {
          callback();
        }
        return;
      }
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.defer = true;
      script.onload = callback;
      document.head.appendChild(script);
    };

    const initMap = () => {
      if (mapRef.current && inputRef.current) {
        const map = new window.google.maps.Map(mapRef.current, {
          center: { lat: 6.9270786, lng: 79.861243 },
          zoom: 13,
        });

        const input = inputRef.current;
        const autocomplete = new window.google.maps.places.Autocomplete(input, {
          fields: ['place_id', 'geometry', 'formatted_address', 'address_components', 'name', 'rating', 'user_ratings_total'],
        });

        autocomplete.bindTo('bounds', map);

        const infowindow = new window.google.maps.InfoWindow();
        infowindow.setContent(infowindowRef.current);

        const marker = new window.google.maps.Marker({ map });

        marker.addListener('click', () => {
          infowindow.open(map, marker);
        });

        autocomplete.addListener('place_changed', () => {
          infowindow.close();

          const place = autocomplete.getPlace();

          if (!place.geometry || !place.geometry.location) {
            return;
          }

          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
          } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
          }

          marker.setPlace({
            placeId: place.place_id,
            location: place.geometry.location,
          });
          marker.setVisible(true);

          if (placeNameRef.current) placeNameRef.current.textContent = place.name;
          if (placeIdRef.current) placeIdRef.current.textContent = place.place_id;
          if (placeAddressRef.current) placeAddressRef.current.textContent = place.formatted_address;
          infowindow.open(map, marker);

          setPlaceId(place.place_id);
          setLat(place.geometry.location.lat());
          setLng(place.geometry.location.lng());

          setName(place.name || '');
          setRating(place.rating || '');
          setUserRatingsTotal(place.user_ratings_total || '');

          const addressComponents = place.address_components.reduce((acc, component) => {
            const types = component.types;
            if (types.includes('street_number')) {
              acc.line1 = component.long_name;
            }
            if (types.includes('route')) {
              acc.line2 = component.long_name;
            }
            if (types.includes('locality')) {
              acc.city = component.long_name;
            }
            if (types.includes('administrative_area_level_1')) {
              acc.state = component.short_name;
            }
            if (types.includes('postal_code')) {
              acc.postalCode = component.long_name;
            }
            return acc;
          }, {});

          const provinceFullName = provinceMappings[addressComponents.state] || addressComponents.state;

          setAddress({
            line1: addressComponents.line1 || '',
            line2: addressComponents.line2 || '',
            city: addressComponents.city || '',
            province: provinceFullName || '',
            postalCode: addressComponents.postalCode || '',
          });
        });
      }
    };

    if (!window.google) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyBxQ52EZuAFE2G3Bz8k8FitxLjDIDV0UwA&libraries=places&v=weekly',
        initMap
      );
    } else {
      initMap();
    }
  }, [setPlaceId, setLat, setLng, setAddress, setName, setRating, setUserRatingsTotal]);

  return (
    <div className="w-full mb-4">
      <div className="w-full max-w-4xl mx-auto p-4">
        <input
          id="pac-input"
          className="w-full p-3 rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="text"
          placeholder="Enter a location"
          ref={inputRef}
        />
      </div>
      <div className="w-full max-w-4xl mx-auto p-4">
        <div id="map" ref={mapRef} className="h-64 w-full rounded-lg shadow-md"></div>
      </div>
    </div>
  );
};

export default FindPlaceID;
