import * as types from './actions.js';

const initialState = {
    hotel: null,
};

const hotelReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_HOTEL_DETAILS:
            return { ...state, hotel: action.payload };
        case types.CLEAR_HOTEL:
            return { ...state, hotel: null };
        default:
            return state;
    }
}

export default hotelReducer;