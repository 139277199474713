import React from 'react';
import { packageCategories, pricingTypes, toSentenceCase } from './utils';

const PackagesList = ({ packages, onEdit }) => (
    <div className="mt-8">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Packages</h2>
        {packages.length > 0 ? (
            packages.map((pkg) => (
                <div key={pkg._id} className="mb-4 p-6 border rounded-lg shadow-lg bg-white">
                    <h3 className="text-xl font-semibold mb-4 text-gray-700">{pkg.name}</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-600">
                        <p><strong className="font-medium text-gray-800">Category:</strong> {packageCategories[pkg.category]}</p>
                        <p><strong className="font-medium text-gray-800">Pricing:</strong> {pricingTypes[pkg.pricing]}</p>
                        <p><strong className="font-medium text-gray-800">Check In:</strong> {pkg.checkIn}</p>
                        <p><strong className="font-medium text-gray-800">Check Out:</strong> {pkg.checkOut}</p>
                        <p><strong className="font-medium text-gray-800">Amenities:</strong> {pkg.amenities.map(toSentenceCase).join(', ')}</p>
                        <p><strong className="font-medium text-gray-800">Price:</strong> {pkg.price}</p>
                        <p><strong className="font-medium text-gray-800">Max Pax:</strong> {pkg.maxPax}</p>
                        <p><strong className="font-medium text-gray-800">Discount:</strong> {pkg.discountPercentage}%</p>
                    </div>
                    <button
                        onClick={() => onEdit(pkg._id)}
                        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                    >
                        Edit Package
                    </button>
                </div>
            ))
        ) : (
            <p className="text-center text-gray-500">No packages available.</p>
        )}
    </div>
);

export default PackagesList;