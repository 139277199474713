import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { addPackage } from '../components/packages/api';
import AddPackageForm from '../components/packages/addPackageForm';

function AddPackage({ isEdit=false }) {
    const { id } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async (packageData) => {
        const success = await addPackage(id, packageData);
        if (success) {
            navigate(`/p/${id}`);
        } else {
            console.error('Failed to add package');
        }
    };

    return (
        <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg px-8 py-6">
            <h2 className="text-2xl font-bold mb-6 text-center">{isEdit ? 'Edit' : 'Add'} Package</h2>
            <AddPackageForm onSubmit={handleSubmit} />
        </div>
    );
}

export default AddPackage;