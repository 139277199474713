import { auth } from "../firebase/firebaseConfigs";

export const registerOrganization = async (businessName) => {
    const token = await auth.currentUser.getIdToken(true);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/organizations`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            businessName: businessName
        })
    });
    const data = await response.json();
    return data;
};