import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from "../../services/firebase/firebaseConfigs";
import { onAuthStateChanged } from "firebase/auth";
import { getUser } from '../../services/user/getUser';
import { setUser, clearUserData } from '../../state/user/actions';
import PageLoader from '../loading/pageLoader';

const PrivateRoute = ({ children }) => {
  const [user, _setUser] = useState(null);
  const stateUser = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      _setUser(currentUser);
      if (!currentUser) {
        navigate('/login');
      } else {
        try {
          const userData = await getUser();
          dispatch(setUser(userData));
        } catch (error) {
          console.error('Failed to fetch user data:', error);
        }
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate, dispatch]);

  useEffect(() => {
    if (stateUser) {
      if (!stateUser.organization && stateUser.role?.name !== 'super-admin') {
        navigate('/register-organization');
      } else if (stateUser.role?.name === 'super-admin') {
        navigate('/admin-dashboard');
      }
    }
  }, [stateUser, navigate]);

  return user === null ? null : (stateUser !== null ? children : <PageLoader />);
};

export default PrivateRoute;