export const packageCategories = {
    dayout: 'Day Out',
    dayout_with_room: 'Day Out with Room',
    overnight: 'Overnight',
    high_tea: 'High Tea',
    lunch: 'Lunch',
    dinner: 'Dinner'
};

export const pricingTypes = {
    per_person: 'Per Person',
    per_room: 'Per Room'
};

export const toSentenceCase = (str) => {
    return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
};
