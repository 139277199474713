import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { registerOrganization } from '../services/organization/registerOrganization';
import { getUser } from '../services/user/getUser';
import { setUser } from '../state/user/actions';
import LoadingButtonComponent from '../components/loading/loader';

const RegisterOrganizationPage = () => {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const stateUser = useSelector((state) => state.user.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
        const response = await registerOrganization(name);
        if (response.businessName) {
            // Organization registered successfully
            getUser().then((data) => {
                dispatch(setUser(data));
                navigate('/dashboard');
            });
        }
        setLoading(false);
    } catch (error) {
      window.alert('Organization registration failed');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (stateUser?.organization) {
      navigate('/dashboard');
    }
  }, [stateUser, navigate]);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-green-400 to-blue-500">
      <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
        <h1 className="text-center text-3xl font-bold mb-6 text-gray-800">Register Organization</h1>
        <input
          type="text"
          placeholder="Business Name"
          value={name}
          onChange={e => setName(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
        />
        <button
          type="submit"
          disabled={loading}
          className="w-full px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600 disabled:opacity-50"
        >
          { loading ? <LoadingButtonComponent/> : 'Register' }
        </button>
      </form>
    </div>
  );
};

export default RegisterOrganizationPage;