import React from 'react';
import { useSelector } from 'react-redux';
import NavBar from './navbar';

const AppWrapper = ({ children }) => {
  const stateUser = useSelector((state) => state.user.user);
  const role = stateUser?.role?.name;

  return (
    <>
      <NavBar role={role} />
      <div className="pt-16">
        {children}
      </div>
    </>
  );
};

export default AppWrapper;
