// reducer.js
import * as types from './actions';

const initialState = {
  user: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER:
      return { ...state, user: action.payload };
    case types.CLEAR_USER_DATA:
        return { ...state, user: null };
    default:
      return state;
  }
};

export default userReducer;