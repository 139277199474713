import { auth } from "../firebase/firebaseConfigs";

export const getUser = async () => {
    const token = await auth.currentUser.getIdToken(true);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/users`, {
        method: 'GET',
        cache: 'reload',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    const data = await response.json();
    return data;
};