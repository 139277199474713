import { auth } from '../../services/firebase/firebaseConfigs';

export const addPackage = async (hotelId, packageData) => {
    const token = await auth.currentUser.getIdToken(true);

    const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/packages`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            ...packageData,
            hotel: hotelId
        })
    });

    return response.ok;
};
