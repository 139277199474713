import React, { useEffect, useState, useCallback } from 'react';
import { auth } from '../services/firebase/firebaseConfigs';
import Switch from 'react-switch';

const AdminDashboard = () => {
  const [organizations, setOrganizations] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [activeTab, setActiveTab] = useState('organizations');
  const [loading, setLoading] = useState(true);

  const fetchOrganizations = useCallback(async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/organizations/all`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setOrganizations(data);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  }, []);

  const fetchHotels = useCallback(async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/hotels`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setHotels(data);
    } catch (error) {
      console.error('Error fetching hotels:', error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchOrganizations(), fetchHotels()]);
      setLoading(false);
    };
    fetchData();
  }, [fetchOrganizations, fetchHotels]);

  const approveOrganization = useCallback(async (id) => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      const user = auth.currentUser;
      await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/organizations/${id}/approve`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ approverId: user.uid })
      });
      setOrganizations(prev => prev.map(org => org._id === id ? { ...org, isApproved: true } : org));
    } catch (error) {
      console.error('Error approving organization:', error);
    }
  }, []);

  const approveHotel = useCallback(async (id) => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      const user = auth.currentUser;
      await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/hotels/${id}/approve`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ approverId: user.uid })
      });
      setHotels(prev => prev.map(hotel => hotel._id === id ? { ...hotel, isApproved: true } : hotel));
    } catch (error) {
      console.error('Error approving hotel:', error);
    }
  }, []);

  const toggleSubscription = useCallback(async (id, isSubscribed) => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/organizations/${id}/subscribe/${isSubscribed ? 'disable' : 'enable'}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setOrganizations(prev => prev.map(org => org._id === id ? { ...org, isSubscribed: !isSubscribed } : org));
    } catch (error) {
      console.error('Error toggling subscription:', error);
    }
  }, []);

  const approvedOrganizations = organizations.filter(org => org.isApproved);
  const notApprovedOrganizations = organizations.filter(org => !org.isApproved);

  const approvedHotels = hotels.filter(hotel => hotel.isApproved);
  const notApprovedHotels = hotels.filter(hotel => !hotel.isApproved);

  if (loading) {
    return (
      <div className="p-6 flex flex-col items-center bg-gray-100 min-h-screen">
        <div className="w-full max-w-4xl bg-white p-6 rounded-lg shadow-lg">
          <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">Loading Admin Dashboard...</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 flex flex-col items-center bg-gray-100 min-h-screen">
      <div className="w-full max-w-4xl bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">Admin Dashboard</h1>
        <div className="flex mb-6">
          <button
            className={`px-4 py-2 w-1/3 rounded-l-lg ${activeTab === 'organizations' ? 'bg-blue-500 text-white' : 'bg-gray-300 hover:bg-gray-400 transition duration-200'}`}
            onClick={() => setActiveTab('organizations')}
          >
            Organizations
          </button>
          <button
            className={`px-4 py-2 w-1/3 ${activeTab === 'hotels' ? 'bg-blue-500 text-white' : 'bg-gray-300 hover:bg-gray-400 transition duration-200'}`}
            onClick={() => setActiveTab('hotels')}
          >
            Hotels
          </button>
          <button
            className={`px-4 py-2 w-1/3 rounded-r-lg ${activeTab === 'subscriptions' ? 'bg-blue-500 text-white' : 'bg-gray-300 hover:bg-gray-400 transition duration-200'}`}
            onClick={() => setActiveTab('subscriptions')}
          >
            Subscriptions
          </button>
        </div>

        {activeTab === 'organizations' && (
          <div className="w-full">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700">Not Approved Organizations</h2>
            <ul className="mb-8 space-y-4">
              {notApprovedOrganizations.map(org => (
                <li key={org._id} className="flex justify-between items-center p-4 bg-gray-200 rounded-md shadow-md transition duration-200 hover:bg-gray-300">
                  <span className="text-lg font-medium">{org.businessName}</span>
                  <button
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
                    onClick={() => approveOrganization(org._id)}
                  >
                    Approve
                  </button>
                </li>
              ))}
            </ul>
            <h2 className="text-2xl font-semibold mb-4 text-gray-700">Approved Organizations</h2>
            <ul className="mb-8 space-y-4">
              {approvedOrganizations.map(org => (
                <li key={org._id} className="flex justify-between items-center p-4 bg-gray-200 rounded-md shadow-md transition duration-200 hover:bg-gray-300">
                  <span className="text-lg font-medium">{org.businessName}</span>
                  <span className="text-green-600 font-semibold">Approved</span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {activeTab === 'hotels' && (
          <div className="w-full">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700">Not Approved Hotels</h2>
            <ul className="mb-8 space-y-4">
              {notApprovedHotels.map(hotel => (
                <li key={hotel._id} className="flex justify-between items-center p-4 bg-gray-200 rounded-md shadow-md transition duration-200 hover:bg-gray-300">
                  <div>
                    <span className="block text-lg font-bold">{hotel.name}</span>
                    <span className="block text-sm text-gray-600">{hotel.organization.businessName}</span>
                  </div>
                  <button
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
                    onClick={() => approveHotel(hotel._id)}
                  >
                    Approve
                  </button>
                </li>
              ))}
            </ul>
            <h2 className="text-2xl font-semibold mb-4 text-gray-700">Approved Hotels</h2>
            <ul className="space-y-4">
              {approvedHotels.map(hotel => (
                <li key={hotel._id} className="flex justify-between items-center p-4 bg-gray-200 rounded-md shadow-md transition duration-200 hover:bg-gray-300">
                  <div>
                    <span className="block text-lg font-bold">{hotel.name}</span>
                    <span className="block text-sm text-gray-600">{hotel.organization.businessName}</span>
                  </div>
                  <span className="text-green-600 font-semibold">Approved</span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {activeTab === 'subscriptions' && (
          <div className="w-full">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700">Manage Subscriptions</h2>
            <ul className="mb-8 space-y-4">
              {organizations.map(org => (
                <li key={org._id} className="flex justify-between items-center p-4 bg-gray-200 rounded-md shadow-md transition duration-200 hover:bg-gray-300">
                  <span className="text-lg font-medium">{org.businessName}</span>
                  <div className="flex items-center">
                    <span className={`mr-2 text-sm font-medium ${org.isSubscribed ? 'text-green-600' : 'text-red-600'}`}>
                      {org.isSubscribed ? 'Subscribed' : 'Not Subscribed'}
                    </span>
                    <Switch
                      onChange={() => toggleSubscription(org._id, org.isSubscribed)}
                      checked={org.isSubscribed}
                      onColor="#4ade80"
                      offColor="#f87171"
                      onHandleColor="#ffffff"
                      offHandleColor="#ffffff"
                      handleDiameter={28}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={22}
                      width={48}
                      className="react-switch"
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
