import React, { useState, useEffect } from 'react';
import { packageCategories, pricingTypes, allAmenities, restrictedAmenities, toSentenceCase } from './utils';

const AddPackageForm = ({ onSubmit, isEdit=false }) => {
    const [packageData, setPackageData] = useState({
        name: '',
        category: '',
        pricing: '',
        checkIn: '',
        checkOut: '',
        description: '',
        amenities: [],
        price: 0,
        maxPax: 0,
        discountPercentage: 0,
        lunchType: ''
    });

    const [validationError, setValidationError] = useState('');

    useEffect(() => {
        if (packageData.category === 'dayout_with_room' || packageData.category === 'overnight') {
            setPackageData(prevData => ({
                ...prevData,
                pricing: 'per_room'
            }));
        } else {
            setPackageData(prevData => ({
                ...prevData,
                pricing: 'per_person'
            }));
        }
    }, [packageData.category]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPackageData({
            ...packageData,
            [name]: name === 'discountPercentage' ? parseInt(value) : value
        });
    };

    const handleNumberChange = (e) => {
        const { name, value } = e.target;
        const intValue = parseInt(value);
        setPackageData({
            ...packageData,
            [name]: intValue >= 0 ? intValue : 0
        });
    };

    const handleAmenitiesChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setPackageData({
                ...packageData,
                amenities: [...packageData.amenities, value]
            });
        } else {
            setPackageData({
                ...packageData,
                amenities: packageData.amenities.filter(amenity => amenity !== value)
            });
        }
    };

    const validateTimes = () => {
        const checkInTime = new Date(`1970-01-01T${packageData.checkIn}:00`);
        const checkOutTime = new Date(`1970-01-01T${packageData.checkOut}:00`);
        const isTimeBasedCategory = ['high_tea', 'lunch', 'dinner'].includes(packageData.category);

        if (isTimeBasedCategory) {
            if (checkInTime >= checkOutTime) {
                setValidationError('End time must be later than start time.');
                return false;
            }
        } else {
            if (packageData.category !== 'overnight'){
                if ((checkInTime >= checkOutTime)) {
                    setValidationError('Check-out time must be later than check-in time.');
                    return false;
                }
            }
        }

        setValidationError('');
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateTimes()) {
            return;
        }
        const images = ["https://placehold.co/600x400@3x.png", "https://placehold.co/800@3x.png"];
        let { category, amenities, lunchType, discountPercentage, ...rest } = packageData;

        if (['high_tea', 'lunch', 'dinner'].includes(category)) {
            amenities = restrictedAmenities;
        }

        // Include selected lunch type in amenities if lunch is selected
        if (packageData.amenities.includes('lunch') && lunchType) {
            amenities = [...amenities, lunchType];
        }

        // Map visible amenities back to their payload values
        const mappedAmenities = amenities.map(amenity => {
            switch (amenity) {
                case 'AC':
                    return 'ac';
                case 'city view':
                    return 'city';
                case 'garden view':
                    return 'garden';
                case 'sea view':
                    return 'sea';
                case 'smoking room':
                    return 'smoking';
                case 'non-smoking room':
                    return 'non-smoking';
                case 'TV':
                    return 'tv';
                case 'lake view':
                    return 'lake';
                case 'beach access':
                    return 'beach';
                default:
                    return amenity;
            }
        });

        const payload = {
            ...rest,
            category,
            amenities: mappedAmenities,
            images,
            checkIn: packageData.checkIn,
            checkOut: packageData.checkOut,
            discountPercentage: parseInt(discountPercentage)
        };

        onSubmit(payload);
    };

    const isTimeBasedCategory = ['high_tea', 'lunch', 'dinner'].includes(packageData.category);
    const isAmenitiesVisible = ['dayout', 'dayout_with_room', 'overnight'].includes(packageData.category);

    const visibleAmenities = isAmenitiesVisible
        ? packageData.category === 'dayout_with_room' || packageData.category === 'overnight'
            ? allAmenities.map(amenity => {
                switch (amenity) {
                    case 'ac':
                        return 'AC';
                    case 'city':
                        return 'city view';
                    case 'garden':
                        return 'garden view';
                    case 'sea':
                        return 'sea view';
                    case 'smoking':
                        return 'smoking room';
                    case 'non-smoking':
                        return 'non-smoking room';
                    case 'tv':
                        return 'TV';
                    case 'lake':
                        return 'lake view';
                    case 'beach':
                        return 'beach access';
                    default:
                        return amenity;
                }
            }).filter(amenity => !['buffet', 'alacarte'].includes(amenity.toLowerCase()))
            : allAmenities.filter(amenity => !['ac', 'deluxe', 'sea', 'city', 'garden', 'lake', 'smoking', 'non-smoking', 'tv', 'toiletries', 'bathtub', 'room only', 'buffet', 'alacarte'].includes(amenity))
                .map(amenity => amenity === 'beach' ? 'beach access' : amenity)
        : [];

    // Added additional amenities for the "overnight" category
    if (packageData.category === 'overnight') {
        visibleAmenities.push('breakfast', 'dinner', 'b&b', 'half board', 'full board');
    }

    const shouldShowLunchType = packageData.amenities.includes('lunch') && isAmenitiesVisible;

    const discountedPrice = packageData.price * (1 - packageData.discountPercentage / 100);

    return (
        <form onSubmit={handleSubmit} className="max-w-4xl mx-auto p-8 bg-white rounded-lg shadow-lg">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <div className="form-group">
                    <label className="block mb-2 font-semibold text-gray-700" htmlFor="name">Package Name:</label>
                    <input type="text" id="name" name="name" value={packageData.name} onChange={handleChange} required className="w-full px-4 py-3 border rounded focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                </div>
                <div className="form-group">
                    <label className="block mb-2 font-semibold text-gray-700" htmlFor="category">Category:</label>
                    <select id="category" name="category" value={packageData.category} onChange={handleChange} required className="w-full px-4 py-3 border rounded focus:outline-none focus:ring-2 focus:ring-indigo-500">
                        <option value="">Select Category</option>
                        {packageCategories.map(({ value, label }) => (
                            <option key={value} value={value}>{label}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label className="block mb-2 font-semibold text-gray-700" htmlFor="pricing">Pricing:</label>
                    <input type="text" id="pricing" name="pricing" value={pricingTypes[packageData.pricing]} readOnly className="w-full px-4 py-3 border rounded bg-gray-100 cursor-not-allowed focus:outline-none" />
                </div>
                <div className="form-group">
                    <label className="block mb-2 font-semibold text-gray-700" htmlFor="checkIn">{isTimeBasedCategory ? 'Start Time' : 'Check In'}:</label>
                    <input type="time" id="checkIn" name="checkIn" value={packageData.checkIn} onChange={handleChange} required className="w-full px-4 py-3 border rounded focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                </div>
                <div className="form-group">
                    <label className="block mb-2 font-semibold text-gray-700" htmlFor="checkOut">{isTimeBasedCategory ? 'End Time' : 'Check Out'}:</label>
                    <input type="time" id="checkOut" name="checkOut" value={packageData.checkOut} onChange={handleChange} required className="w-full px-4 py-3 border rounded focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                </div>
                {validationError && (
                    <div className="md:col-span-2 text-red-600 text-center">
                        {validationError}
                    </div>
                )}
                <div className="form-group md:col-span-2">
                    <label className="block mb-2 font-semibold text-gray-700" htmlFor="description">Description:</label>
                    <textarea id="description" name="description" value={packageData.description} onChange={handleChange} required className="w-full px-4 py-3 border rounded h-32 focus:outline-none focus:ring-2 focus:ring-indigo-500"></textarea>
                </div>
                {isAmenitiesVisible && (
                    <div className="form-group md:col-span-2">
                        <label className="block mb-2 font-semibold text-gray-700">Amenities:</label>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            {visibleAmenities.map((amenity) => (
                                <label key={amenity} className={`flex items-center space-x-2 ${amenity.toLowerCase() === 'room only' ? 'text-red-600' : ''}`}>
                                    <input type="checkbox" value={amenity} checked={packageData.amenities.includes(amenity)} onChange={handleAmenitiesChange} className={`form-checkbox h-5 w-5 ${amenity.toLowerCase() === 'room only' ? 'text-red-600' : 'text-indigo-600'} focus:ring-indigo-500`} />
                                    <span>{toSentenceCase(amenity)}</span>
                                </label>
                            ))}
                        </div>
                        {shouldShowLunchType && (
                            <div className="mt-4">
                                <label className="block mb-2 font-semibold text-gray-700">Meal Type:</label>
                                <div className="flex space-x-4">
                                    <label className="flex items-center space-x-2">
                                        <input type="radio" name="lunchType" value="buffet" checked={packageData.lunchType === 'buffet'} onChange={handleChange} className="form-radio h-5 w-5 text-indigo-600 focus:ring-indigo-500" />
                                        <span>Buffet</span>
                                    </label>
                                    <label className="flex items-center space-x-2">
                                        <input type="radio" name="lunchType" value="alacarte" checked={packageData.lunchType === 'alacarte'} onChange={handleChange} className="form-radio h-5 w-5 text-indigo-600 focus:ring-indigo-500" />
                                        <span>A La Carte</span>
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className="form-group">
                    <label className="block mb-2 font-semibold text-gray-700" htmlFor="price">Price ({pricingTypes[packageData.pricing]}):</label>
                    <input type="number" id="price" name="price" value={packageData.price} onChange={handleNumberChange} required className="w-full px-4 py-3 border rounded focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                </div>
                <div className="form-group">
                    <label className="block mb-2 font-semibold text-gray-700" htmlFor="maxPax">Max Pax:</label>
                    <input type="number" id="maxPax" name="maxPax" value={packageData.maxPax} onChange={handleNumberChange} required className="w-full px-4 py-3 border rounded focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                </div>
                <div className="form-group">
                    <label className="block mb-2 font-semibold text-gray-700" htmlFor="discountPercentage">Discount Percentage (%):</label>
                    <input type="number" id="discountPercentage" name="discountPercentage" value={packageData.discountPercentage} onChange={handleNumberChange} required className="w-full px-4 py-3 border rounded focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                </div>
                <div className="form-group md:col-span-2">
                    <label className="block mb-2 font-semibold text-gray-700" htmlFor="discountedPrice">Price After Discount:</label>
                    <div className="w-full px-4 py-3 border rounded bg-gray-100 text-gray-800">
                        {discountedPrice.toFixed(2)}
                    </div>
                </div>
            </div>
            <button type="submit" className="w-full mt-6 bg-indigo-600 text-white font-bold py-3 px-4 rounded-lg hover:bg-indigo-700 transition duration-200">Add Package</button>
        </form>
    );
};

export default AddPackageForm;
