import AddHotelForm from "../components/hotels/addHotelsForm";

function AddHotelPage({ isEdit = false }) {
    return (
        <div className="p-6 flex flex-col items-center">
            <h1 className="text-4xl">{ isEdit ? 'Edit' : 'Add' } Property</h1>
            <AddHotelForm isEdit={isEdit} />
        </div>
    );
}

export default AddHotelPage;