export const SET_HOTEL_DETAILS = 'SET_HOTEL_DETAILS';
export const CLEAR_HOTEL = 'CLEAR_HOTEL';

export const setHotelDetails = (payload) => ({
    type: SET_HOTEL_DETAILS,
    payload
});

export const clearHotel = () => ({
    type: CLEAR_HOTEL
});