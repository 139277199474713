import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth, storage } from '../../services/firebase/firebaseConfigs';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

const mapAmenities = (amenities, forward = true) => {
    const amenitiesMapping = {
        'Free Wifi': 'wifi',
        'Free Parking': 'parking',
        'Swimming Pool': 'pool',
        'Bar': 'bar',
        'Restaurant': 'restaurant',
        'Gym': 'gym',
        'Spa': 'spa',
        '24 x 7 Security': 'security',
        'Beach Access': 'beach',
        'Laundry Service': 'laundry',
        'Baggage Storage': 'baggage'
    };

    const reverseAmenitiesMapping = Object.fromEntries(
        Object.entries(amenitiesMapping).map(([key, value]) => [value, key])
    );

    const mapping = forward ? amenitiesMapping : reverseAmenitiesMapping;

    return amenities.map(amenity => mapping[amenity] || amenity);
};

const mapOptions = (options, forward = true) => {
    const optionsMapping = {
        'Friendventure': 'friendventure',
        'Romance': 'romance',
        'Layover': 'layover',
        'Work': 'work',
        'Picnic': 'picnic',
        'Wellness': 'wellness',
        'Photoshoot': 'photoshoot',
        'Family': 'family',
        'Group': 'group',
        'Bachelorette': 'bachelorette'
    };

    const reverseOptionsMapping = Object.fromEntries(
        Object.entries(optionsMapping).map(([key, value]) => [value, key])
    );

    const mapping = forward ? optionsMapping : reverseOptionsMapping;

    return options.map(option => mapping[option] || option);
};

export const useAddHotelsForm = ( isEdit = false ) => {
    const stateSelectedHotel = useSelector(state => state.hotel.hotel);
    const [hotel, setHotel] = useState({
        name: '',
        email: '',
        type: '',
        rating: 0,
        description: '',
        address: {
            line1: '',
            line2: '',
            line3: '',
        },
        contactDetails: {
            tp: [],
            email: '',
            facebook: '',
            instagram: '',
            tiktok: '',
            googlemaps: '',
            website: '',
            whatsapp: '',
        },
        location: {
            city: '',
            district: '',
            province: '',
            lat: 0,
            lng: 0,
        },
        images: [],
        amenities: [],
        options: [],
        price: 0,
        discountPercentage: 0,
    });
    const [province, setProvince] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [contactNumbers, setContactNumbers] = useState(hotel.contactDetails.tp || []);
    const [hotelAmenities, setAmenities] = useState(hotel.amenities || []);
    const [hotelOptions, setOptions] = useState(hotel.options || []);
    const [imageFiles, setImageFiles] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [placeId, setPlaceId] = useState(hotel.location.placeid || '');
    const [lat, setLat] = useState(hotel.location.lat || '');
    const [lng, setLng] = useState(hotel.location.lng || '');
    const [address, setAddress] = useState({
      line1: hotel.address.line1 || '',
      line2: hotel.address.line2 || '',
      city: hotel.address.city || '',
      province: hotel.address.province || '',
      postalCode: hotel.address.postalCode || '',
    });
    const [name, setName] = useState(hotel.name || '');
    const [rating, setRating] = useState(hotel.rating || '');
    const [ratingTotal, setUserRatingsTotal] = useState(hotel.ratingTotal || '');

    // set values from selected hotel if editing
    useEffect(() => {
        if (isEdit && stateSelectedHotel) {
            setHotel(stateSelectedHotel);
            setProvince(stateSelectedHotel.location.province);
            setDistrict(stateSelectedHotel.location.district);
            setCity(stateSelectedHotel.location.city);
            setContactNumbers(stateSelectedHotel.contactDetails.tp);
            setAmenities(mapAmenities(stateSelectedHotel.amenities, false));
            setOptions(mapOptions(stateSelectedHotel.options, false));
            setImageUrls(stateSelectedHotel.images);
            setPlaceId(stateSelectedHotel.location.placeId);
            setLat(stateSelectedHotel.location.lat);
            setLng(stateSelectedHotel.location.lng);
            setAddress({
                line1: stateSelectedHotel.address.line1,
                line2: stateSelectedHotel.address.line2,
                city: stateSelectedHotel.address.city,
                province: stateSelectedHotel.address.province,
                postalCode: stateSelectedHotel.address.postalCode,
            });
            setName(stateSelectedHotel.name);
            setRating(stateSelectedHotel.rating);
            setUserRatingsTotal(stateSelectedHotel.ratingTotal);
        } else if (isEdit && !stateSelectedHotel) {
            navigate('/dashboard')
        }
    }, [isEdit, stateSelectedHotel]);


    const navigate = useNavigate();

    const handleAmenitiesChange = (event) => {
        const amenity = event.target.value;
        if (event.target.checked) {
            setAmenities(prevAmenities => [...prevAmenities, amenity]);
        } else {
            setAmenities(prevAmenities => prevAmenities.filter(a => a !== amenity));
        }
    };

    const handleOptionsChange = (event) => {
        const option = event.target.value;
        if (event.target.checked) {
            setOptions(prevOptions => [...prevOptions, option]);
        } else {
            setOptions(prevOptions => prevOptions.filter(o => o !== option));
        }
    };

    const handleDistrictChange = (district) => {
        setCity('');
        setDistrict(district);
    };

    const handleProvinceChange = (province) => {
        setCity('');
        setDistrict('');
        setProvince(province);
    };

    const handleAddContactNumber = () => {
        setContactNumbers([...contactNumbers, '']);
    };

    const handleContactNumberChange = (event, index) => {
        const newContactNumbers = [...contactNumbers];
        newContactNumbers[index] = event.target.value;
        setContactNumbers(newContactNumbers);
    };

    const handleRemoveContactNumber = (index) => {
        const newContactNumbers = [...contactNumbers];
        newContactNumbers.splice(index, 1);
        setContactNumbers(newContactNumbers);
    };

    const handleAddImageFile = (event) => {
        const files = Array.from(event.target.files);
        setImageFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const handleRemoveImageFile = (index) => {
        const newImageFiles = [...imageFiles];
        newImageFiles.splice(index, 1);
        setImageFiles(newImageFiles);
    };

    const handleRemoveUploadedImage = (index) => {
        const copiedUrls = [...imageUrls];
        copiedUrls.splice(index, 1);
        setImageUrls(copiedUrls);
    }

    const handleChange = (e) => {
        setHotel({
            ...hotel,
            [e.target.name]: e.target.value,
        });
    };

    const handleNestedChange = (e, field, subfield) => {
        setHotel({
            ...hotel,
            [field]: {
                ...hotel[field],
                [subfield]: e.target.value,
            },
        });
    };

    const handleSubmit = async (payload) => {
        setSubmitting(true);

        // Step 1: Upload all images to Firebase Storage
        const uploadPromises = imageFiles.map(file => {
            const storageRef = ref(storage, `hotels/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            return new Promise((resolve, reject) => {
                uploadTask.on('state_changed', 
                    (snapshot) => {
                        // Optional: Handle progress
                    }, 
                    (error) => {
                        reject(error);
                    }, 
                    async () => {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        resolve(downloadURL);
                    }
                );
            });
        });

        try {
            const urls = await Promise.all(uploadPromises);
            setImageUrls([ ...imageUrls, ...urls]);

            // Step 2: Prepare the payload with the image URLs
            const updatedHotel = {
                ...payload,
                type: payload.type.toLowerCase(),
                contactDetails: {
                    ...payload.contactDetails,
                    tp: contactNumbers
                },
                location: {
                    ...payload.location,
                    lat: parseFloat(payload.location.lat),
                    lng: parseFloat(payload.location.lng)
                },
                images: [ ...imageUrls, ...urls],
                amenities: mapAmenities(hotelAmenities),
                options: mapOptions(hotelOptions),
                price: parseFloat(payload.price),
                discountPercentage: parseFloat(payload.discountPercentage)
            };

            // Step 3: Send the payload to the backend
            const token = await auth.currentUser.getIdToken(true);
            let response;
            if (isEdit) {
                response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/hotels/${stateSelectedHotel._id}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(updatedHotel)
                });
            } else {
                response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/hotels`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(updatedHotel)
                });
            }
            
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Response Error:', errorText);
                throw new Error('Network response was not ok');
            }

            await response.json();
            // Navigate back to the dashboard on success
            navigate('/dashboard');
        } catch (error) {
            console.error('Error:', error);
            // Handle error (e.g., show an error message)
        } finally {
            setSubmitting(false);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        handleSubmit({
          ...hotel,
          name,
          rating,
          ratingTotal: ratingTotal,
          contactDetails: {
            ...hotel.contactDetails,
            tp: contactNumbers,
          },
          location: {
            ...hotel.location,
            province: address.province,
            district,
            city:address.city,
            placeId: placeId,
            lat: lat,
            lng: lng,
          },
          address: {
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            province: address.province,
            postalCode: address.postalCode,
          },
          amenities: hotelAmenities,
          options: hotelOptions,
        });
      };

    return {
        hotel,
        contactNumbers,
        imageFiles,
        district,
        hotelAmenities,
        hotelOptions,
        submitting,
        placeId,
        lat,
        lng,
        address,
        name,
        rating,
        ratingTotal,
        imageUrls,
        setPlaceId,
        setLat,
        setLng,
        setAddress,
        setName,
        setRating,
        setUserRatingsTotal,
        handleAddContactNumber,
        handleContactNumberChange,
        handleRemoveContactNumber,
        handleAddImageFile,
        handleRemoveImageFile,
        handleRemoveUploadedImage,
        handleChange,
        handleNestedChange,
        handleDistrictChange,
        handleAmenitiesChange,
        handleOptionsChange,
        handleFormSubmit,
    };
};
