/* global google */
import React from 'react';
import { useAddHotelsForm } from './useAddHotelsForm';
import { locationData, amenities, options } from './addHotelConfigs';
import FindPlaceID from './findPlaceID';

function AddHotelForm({ isEdit = false }) {
  const {
    hotel,
    contactNumbers,
    imageFiles,
    district,
    hotelAmenities,
    hotelOptions,
    submitting,
    placeId,
    lat,
    lng,
    address,
    name,
    rating,
    ratingTotal,
    imageUrls,
    setPlaceId,
    setLat,
    setLng,
    setAddress,
    setName,
    setRating,
    setUserRatingsTotal,
    handleAddContactNumber,
    handleContactNumberChange,
    handleRemoveContactNumber,
    handleAddImageFile,
    handleRemoveImageFile,
    handleRemoveUploadedImage,
    handleChange,
    handleNestedChange,
    handleDistrictChange,
    handleAmenitiesChange,
    handleOptionsChange,
    handleFormSubmit,
  } = useAddHotelsForm(isEdit);

  return (
    <>
      {submitting && (
        <div className="fixed inset-0 bg-white bg-opacity-80 flex items-center justify-center z-50">
          <div className="spinner border-t-4 border-blue-600 w-12 h-12 rounded-full animate-spin"></div>
        </div>
      )}
      <form onSubmit={handleFormSubmit} className={`grid grid-cols-1 gap-8 p-8 lg:max-w-6xl xl:max-w-7xl 2xl:max-w-8xl mx-auto bg-white rounded-lg shadow-md ${submitting ? 'pointer-events-none' : ''}`}>
        {/* Find Place ID Component */}
        { !isEdit && <div className="col-span-1 md:col-span-2">
          <h2 className="text-2xl font-bold mb-4">Find in Google Maps</h2>
          <FindPlaceID
            setPlaceId={setPlaceId}
            setLat={setLat}
            setLng={setLng}
            setAddress={setAddress}
            setName={setName}
            setRating={setRating}
            setUserRatingsTotal={setUserRatingsTotal}
          />
        </div> }

        {/* Name Field */}
        <div className="col-span-1 md:col-span-2">
          <h2 className="text-2xl font-bold mb-4">Basic Details</h2>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="type" className="block text-sm font-medium text-gray-700">Type:</label>
            <select
              id="type"
              name="type"
              value={hotel.type}
              onChange={handleChange}
              required
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">Select Type</option>
              <option value="hotel">Hotel</option>
              <option value="villa">Villa</option>
              <option value="restaurant">Restaurant</option>
              <option value="resort">Resort</option>
              <option value="guesthouse">Guesthouse</option>
              <option value="inn">Inn</option>
              <option value="other">Other</option>
            </select>
          </div>

          {/* Rating and User Ratings Total Fields */}
          <div className="mb-4">
            <label htmlFor="rating" className="block text-sm font-medium text-gray-700">Rating:</label>
            <input
              type="number"
              id="rating"
              name="rating"
              value={rating}
              onChange={(e) => setRating(e.target.value)}
              required
              readOnly
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 cursor-not-allowed"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="userRatingsTotal" className="block text-sm font-medium text-gray-700">Total User Ratings:</label>
            <input
              type="number"
              id="userRatingsTotal"
              name="userRatingsTotal"
              value={ratingTotal}
              onChange={(e) => setUserRatingsTotal(e.target.value)}
              required
              readOnly
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 cursor-not-allowed"
            />
          </div>
        </div>

        {/* Description */}
        <div className="col-span-1 md:col-span-2">
          <h2 className="text-2xl font-bold mb-4">Description</h2>
          <div className="mb-4">
            <textarea
              id="description"
              name="description"
              value={hotel.description}
              onChange={handleChange}
              required
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 h-32"
            />
          </div>
        </div>

        {/* Address Fields */}
        <div className="col-span-1 md:col-span-2">
          <h2 className="text-2xl font-bold mb-4">Address</h2>
          <div className="mb-4">
            <label htmlFor="line1" className="block text-sm font-medium text-gray-700">Line 1:</label>
            <input
              type="text"
              id="line1"
              name="line1"
              value={address.line1}
              onChange={(e) => setAddress({ ...address, line1: e.target.value })}
              required
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="line2" className="block text-sm font-medium text-gray-700">Line 2:</label>
            <input
              type="text"
              id="line2"
              name="line2"
              value={address.line2}
              onChange={(e) => setAddress({ ...address, line2: e.target.value })}
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="city" className="block text-sm font-medium text-gray-700">City:</label>
            <input
              type="text"
              id="city"
              name="city"
              value={address.city}
              onChange={(e) => setAddress({ ...address, city: e.target.value })}
              required
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="province-google" className="block text-sm font-medium text-gray-700">Province:</label>
            <input
              type="text"
              id="province-google"
              name="province-google"
              value={address.province}
              onChange={(e) => setAddress({ ...address, province: e.target.value })}
              required
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700">Postal Code:</label>
            <input
              type="text"
              id="postalCode"
              name="postalCode"
              value={address.postalCode}
              onChange={(e) => setAddress({ ...address, postalCode: e.target.value })}
              required
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="district" className="block text-sm font-semibold text-gray-700 mb-1">District:</label>
            <div className="relative"> <select id="district" name="district" value={district} onChange={(e) => handleDistrictChange(e.target.value)} required disabled={submitting} className="block w-full bg-white border border-gray-300 px-4 py-2 pr-8 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 appearance-none transition duration-150 ease-in-out" >
                <option value="" disabled>Select District</option>
                {address.province && locationData[address.province] && locationData[address.province].districts && Object.keys(locationData[address.province].districts).map((district) => (
                    <option key={district} value={district}>{district}</option>
                ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <svg className="w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
        </div>
        </div>

        {/* Location Fields */}
        <div className="col-span-1 md:col-span-2">
          <h2 className="text-2xl font-bold mb-4">Location Details</h2>
          <div className="mb-4">
            <label htmlFor="placeId" className="block text-sm font-medium text-gray-700">Google Place ID:</label>
            <input
              type="text"
              id="placeId"
              name="placeId"
              value={placeId}
              onChange={(e) => setPlaceId(e.target.value)}
              readOnly
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 cursor-not-allowed"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="lat" className="block text-sm font-medium text-gray-700">Latitude:</label>
            <input
              type="text"
              id="lat"
              name="lat"
              value={lat}
              onChange={(e) => setLat(e.target.value)}
              readOnly
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 cursor-not-allowed"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="lng" className="block text-sm font-medium text-gray-700">Longitude:</label>
            <input
              type="text"
              id="lng"
              name="lng"
              value={lng}
              onChange={(e) => setLng(e.target.value)}
              readOnly
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 cursor-not-allowed"
            />
          </div>
        </div>

        {/* Contact Details */}
        <div className="col-span-1 md:col-span-2">
          <h2 className="text-2xl font-bold mb-4">Contact Details</h2>
          <div className="mb-4">
            <label htmlFor="contactEmail" className="block text-sm font-medium text-gray-700">Email:</label>
            <input
              type="email"
              id="contactEmail"
              name="contactEmail"
              value={hotel.contactDetails.email}
              onChange={(e) => handleNestedChange(e, 'contactDetails', 'email')}
              required
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="facebook" className="block text-sm font-medium text-gray-700">Facebook:</label>
            <input
              type="url"
              id="facebook"
              name="facebook"
              value={hotel.contactDetails.facebook}
              onChange={(e) => handleNestedChange(e, 'contactDetails', 'facebook')}
              pattern="https://.*"
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="instagram" className="block text-sm font-medium text-gray-700">Instagram:</label>
            <input
              type="url"
              id="instagram"
              name="instagram"
              value={hotel.contactDetails.instagram}
              onChange={(e) => handleNestedChange(e, 'contactDetails', 'instagram')}
              pattern="https://.*"
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="tiktok" className="block text-sm font-medium text-gray-700">TikTok:</label>
            <input
              type="url"
              id="tiktok"
              name="tiktok"
              value={hotel.contactDetails.tiktok}
              onChange={(e) => handleNestedChange(e, 'contactDetails', 'tiktok')}
              pattern="https://.*"
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="youtube" className="block text-sm font-medium text-gray-700">YouTube:</label>
            <input
              type="url"
              id="youtube"
              name="youtube"
              value={hotel.contactDetails.youtube}
              onChange={(e) => handleNestedChange(e, 'contactDetails', 'youtube')}
              pattern="https://.*"
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="website" className="block text-sm font-medium text-gray-700">Website:</label>
            <input
              type="url"
              id="website"
              name="website"
              value={hotel.contactDetails.website}
              onChange={(e) => handleNestedChange(e, 'contactDetails', 'website')}
              pattern="https://.*"
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="whatsapp" className="block text-sm font-medium text-gray-700">WhatsApp:</label>
            <input
              type="tel"
              id="whatsapp"
              name="whatsapp"
              value={hotel.contactDetails.whatsapp}
              onChange={(e) => handleNestedChange(e, 'contactDetails', 'whatsapp')}
              pattern="0[0-9]{9}"
              disabled={submitting}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>

        {/* Contact Numbers */}
        <div className="col-span-1 md:col-span-2">
          <h2 className="text-2xl font-bold mb-4">Contact Numbers</h2>
          {contactNumbers.map((number, index) => (
            <div key={index} className="flex items-center mb-4">
              <input
                type="tel"
                value={number}
                onChange={(event) => handleContactNumberChange(event, index)}
                required
                disabled={submitting}
                pattern="0[0-9]{9}"
                className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              <button type="button" onClick={() => handleRemoveContactNumber(index)} className="ml-2 bg-red-500 text-white px-4 py-2 rounded-md shadow-sm focus:outline-none hover:bg-red-600 transition duration-200" disabled={submitting}>Remove</button>
            </div>
          ))}
          <button type="button" onClick={handleAddContactNumber} className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm focus:outline-none hover:bg-blue-600 transition duration-200" disabled={submitting}>Add Contact Number</button>
        </div>

        {/* Amenities */}
        <div className="col-span-1 md:col-span-2">
          <h2 className="text-2xl font-bold mb-4">Amenities</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {amenities.map((amenity, index) => (
              <label key={index} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value={amenity === 'beach' ? 'Beach Access' : amenity}
                  checked={hotelAmenities.includes(amenity)}
                  onChange={handleAmenitiesChange}
                  disabled={submitting}
                  className="form-checkbox h-5 w-5 text-indigo-600 focus:ring-indigo-500"
                />
                <span>{amenity === 'beach' ? 'Beach Access' : amenity}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Dayout Options */}
        <div className="col-span-1 md:col-span-2">
          <h2 className="text-2xl font-bold mb-4">Dayout Options</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {options.map((option, index) => (
              <label key={index} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value={option}
                  checked={hotelOptions.includes(option)}
                  onChange={handleOptionsChange}
                  disabled={submitting}
                  className="form-checkbox h-5 w-5 text-indigo-600 focus:ring-indigo-500"
                />
                <span>{option}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Images */}
        <div className="col-span-1 md:col-span-2">
          <h2 className="text-2xl font-bold mb-4">Images</h2>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleAddImageFile}
            disabled={submitting}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          />
          {(imageFiles.length > 0 || imageUrls) && (
            <div>
              <h4 className="mt-4 mb-2 text-lg font-semibold">Selected Images:</h4>
              <ul className="flex space-x-4 overflow-x-auto">
                {imageFiles.map((file, index) => (
                  <li key={index} className="relative">
                    <img src={URL.createObjectURL(file)} alt={`thumbnail-${index}`} className="thumbnail w-24 h-24 object-cover rounded-md shadow-sm" />
                    <button type="button" onClick={() => handleRemoveImageFile(index)} className="absolute top-1 right-1 bg-red-500 text-white px-2 py-1 rounded-full text-sm shadow-sm focus:outline-none hover:bg-red-600 transition duration-200" disabled={submitting}>Remove</button>
                  </li>
                ))}
              </ul>
              <ul className="flex space-x-4 overflow-x-auto">
                {imageUrls.map((url, index) => (
                  <li key={index} className="relative">
                    <img src={url} alt={`thumbnail-${index}`} className="thumbnail w-24 h-24 object-cover rounded-md shadow-sm" />
                    <button type="button" onClick={() => handleRemoveUploadedImage(index)} className="absolute top-1 right-1 bg-red-500 text-white px-2 py-1 rounded-full text-sm shadow-sm focus:outline-none hover:bg-red-600 transition duration-200" disabled={submitting}>Remove</button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <div className="col-span-1 md:col-span-2 flex justify-center">
          <button type="submit" className="bg-indigo-600 text-white font-bold py-2 px-4 rounded shadow-sm hover:bg-indigo-700 transition duration-200 focus:outline-none" disabled={submitting}>Submit</button>
        </div>
      </form>
    </>
  );
}

export default AddHotelForm;
