import { auth } from '../../services/firebase/firebaseConfigs';

export const fetchHotelDetails = async (id) => {
    const token = await auth.currentUser.getIdToken(true);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/hotels/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });

    if (response.ok) {
        return response.json();
    } else {
        console.error('Failed to fetch hotel details');
        return null;
    }
};
