export const locationData = {
  'Central': {
    districts: {
      'Kandy': ['Kandy', 'Peradeniya', 'Gampola', 'Katugastota'],
      'Matale': ['Matale', 'Dambulla', 'Galewela'],
      'Nuwara Eliya': ['Nuwara Eliya', 'Hatton', 'Ginigathhena']
    }
  },
  'Eastern': {
    districts: {
      'Trincomalee': ['Trincomalee', 'Kinniya', 'Kantalai'],
      'Batticaloa': ['Batticaloa', 'Kattankudy', 'Eravur'],
      'Ampara': ['Ampara', 'Kalmunai', 'Sainthamaruthu']
    }
  },
  'Nothern': {
    districts: {
      'Jaffna': ['Jaffna', 'Nallur', 'Point Pedro'],
      'Kilinochchi': ['Kilinochchi', 'Poonakari', 'Karachchi'],
      'Mannar': ['Mannar', 'Madhu', 'Talaimannar'],
      'Mullaitivu': ['Mullaitivu', 'Oddusuddan', 'Puthukkudiyiruppu'],
      'Vavuniya': ['Vavuniya', 'Nedunkerni', 'Puliyankulam']
    }
  },
  'Southern': {
    districts: {
      'Galle': ['Galle', 'Ambalangoda', 'Hikkaduwa'],
      'Matara': ['Matara', 'Weligama', 'Mirissa'],
      'Hambantota': ['Hambantota', 'Tissamaharama', 'Ambalantota']
    }
  },
  'Western': {
    districts: {
      'Colombo': ['Colombo', 'Dehiwala', 'Mount Lavinia'],
      'Gampaha': ['Gampaha', 'Negombo', 'Wattala'],
      'Kalutara': ['Kalutara', 'Panadura', 'Horana']
    }
  },
  'North Western': {
    districts: {
      'Kurunegala': ['Kurunegala', 'Kuliyapitiya', 'Naram'],
      'Puttalam': ['Puttalam', 'Chilaw', 'Marawila']
    }
  },
  'North Central': {
    districts: {
      'Anuradhapura': ['Anuradhapura', 'Kekirawa', 'Medawachchiya'],
      'Polonnaruwa': ['Polonnaruwa', 'Hingurakgoda', 'Medirigiriya']
    }
  },
  'Uva': {
    districts: {
      'Badulla': ['Badulla', 'Bandarawela', 'Haputale'],
      'Monaragala': ['Monaragala', 'Wellawaya', 'Bibile']
    }
  },
  'Sabaragamuwa': {
    districts: {
      'Ratnapura': ['Ratnapura', 'Embilipitiya', 'Balangoda'],
      'Kegalle': ['Kegalle', 'Mawanella', 'Dehiowita']
    }
  }
};

export const amenities = [
  'Free Wifi',
  'Free Parking',
  'Swimming Pool',
  'Bar',
  'Restaurant',
  'Gym',
  'Spa',
  '24 x 7 Security',
  'Beach',
  'Laundry Service',
  'Baggage Storage'
];


export const options = ["Romance", "Friendventure", "Layover", "Work", "Picnic", "Wellness", "Photoshoot", "Family", "Group", "Bachelorette"];