import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../services/firebase/firebaseConfigs';

const NavBar = ({ role }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  return (
    <nav className="fixed top-0 w-full flex items-center justify-between p-4 bg-gray-800 text-white shadow-lg z-50">
      <div className="flex space-x-4">
        {role !== 'super-admin' && (
          <>
            <button
              onClick={() => navigate('/dashboard')}
              className="px-4 py-2 rounded bg-blue-500 hover:bg-blue-600 transition duration-200"
            >
              Hotels
            </button>
            <button
              onClick={() => navigate('/')}
              className="px-4 py-2 rounded bg-blue-500 hover:bg-blue-600 transition duration-200"
            >
              Settings
            </button>
          </>
        )}
      </div>
      <h1 className="text-2xl font-bold">Zeylon Life</h1>
      <div className="flex space-x-4">
        <button
          onClick={handleLogout}
          className="px-4 py-2 rounded bg-red-500 hover:bg-red-600 transition duration-200"
        >
          Logout
        </button>
      </div>
    </nav>
  );
};

export default NavBar;
