import React from 'react';

const HotelInfo = ({ hotel }) => (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">{hotel.name}</h1>
        <div className="flex justify-center mb-6">
            <img src={hotel.images[0]} alt={hotel.name} className="w-64 h-64 object-cover rounded-lg shadow-md" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-lg">
            <div>
                <p className="text-gray-700"> <strong className="font-semibold">Type:</strong> {hotel.type.charAt(0).toUpperCase() + hotel.type.slice(1).toLowerCase()} </p>
                <p className="text-gray-700"><strong className="font-semibold">City:</strong> {hotel.location.city}</p>
                <p className="text-gray-700"><strong className="font-semibold">District:</strong> {hotel.location.district}</p>
            </div>
            <div>
                <p className="text-gray-700"><strong className="font-semibold">Contact Email:</strong> {hotel.contactDetails.email}</p>
                <p className="text-gray-700"><strong className="font-semibold">Contact Numbers:</strong> {hotel.contactDetails.tp.join(', ')}</p>
            </div>
        </div>
    </div>
);

export default HotelInfo;
