// actions.js
export const SET_USER = 'SET_USER';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const clearUserData = () => ({
  type: CLEAR_USER_DATA,
});