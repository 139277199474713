import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoadingButtonComponent from '../components/loading/loader';

function RegisterPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [tp, setTp] = useState('');
    const [name, setName] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (password !== confirmPassword) {
            alert("Passwords don't match");
            setLoading(false);
            return;
        }
        const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;

        const response = await fetch(`${backendUrl}/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                name: name,
                tp: tp,
                password: password
            })
        });

        const result = await response.json();
        setLoading(false);

        if (response.ok) {
            setSuccessMessage('User successfully registered. Please login.');
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } else {
            window.alert(`Registration failed: ${result.message}`);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-green-400 to-blue-500">
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
                <h1 className="text-center text-3xl font-bold mb-6 text-gray-800">Register</h1>
                {successMessage && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                        <strong className="font-bold">Success! </strong>
                        <span className="block sm:inline">{successMessage}</span>
                    </div>
                )}
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
                <input
                    type="text"
                    placeholder="Contact Number"
                    value={tp}
                    onChange={e => setTp(e.target.value)}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
                <input
                    type="text"
                    placeholder="Full Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
                <input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
                <button
                    type="submit"
                    disabled={loading}
                    className="w-full px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600 disabled:opacity-50"
                >
                    {loading ? <LoadingButtonComponent /> : 'Register'}
                </button>
                <Link to="/login" className="mt-4 text-center text-green-700 hover:underline">Already registered? Login</Link>
            </form>
        </div>
    );
}

export default RegisterPage;