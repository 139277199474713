import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import HotelInfo from '../components/hotels/hotelInfo';
import PackagesList from '../components/hotels/packagesList';
import { fetchHotelDetails } from '../components/hotels/api';

function HotelDetail() {
    const { id } = useParams();
    const [hotel, setHotel] = useState(null);
    const navigate = useNavigate();

    const getHotelDetails = useCallback(async () => {
        const data = await fetchHotelDetails(id);
        setHotel(data);
    }, [id]);

    useEffect(() => {
        getHotelDetails();
    }, [getHotelDetails]);

    if (!hotel) return <div className="flex items-center justify-center h-screen">Loading...</div>;

    const isHotelApproved = hotel.isApproved;

    return (
        <div className="p-6 max-w-4xl mx-auto bg-white shadow-md rounded-lg">
            <HotelInfo hotel={hotel} />
            <div className="flex justify-center mt-6 mb-8">
                <button
                    className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded`}
                    onClick={() => navigate(`/p/${id}/edit`)}
                >
                    Edit Hotel Details
                </button>
            </div>
            <div className="flex justify-center mt-6 mb-8">
                <button
                    className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded ${!isHotelApproved ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={() => navigate(`/p/${id}/add-package`)}
                    disabled={!isHotelApproved}
                >
                    Add Package
                </button>
            </div>
            <PackagesList packages={hotel.packages} onEdit={(id) => console.log(id)}/>
        </div>
    );
}

export default HotelDetail;