import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import PrivateRoute from './components/privateRoutes/privateRoute';
import RegisterPage from './pages/register';
import LoginPage from './pages/login';
import RegisterOrganizationPage from './pages/registerOrganization';
import store from './state/store';
import Dashboard from './pages/dashboard';
import AppWrapper from './components/navbar/navbarWrapper';
import FindPlaceID from './components/hotels/findPlaceID';
import AddHotelPage from './pages/addHotels';
import HotelDetail from './pages/hotelDetail';
import AddPackage from './pages/addPackage';
import AdminDashboard from './pages/adminDashboard';
import ForgotPassword from './pages/forgetPassword';
import { analytics, logEvent } from './services/firebase/firebaseConfigs';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AnalyticsHandler />
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forget-password" element={<ForgotPassword />} />
          <Route path="/register-organization" element={<PrivateRoute><AppWrapper><RegisterOrganizationPage /></AppWrapper></PrivateRoute>} />
          <Route path="/dashboard" element={<PrivateRoute><AppWrapper><Dashboard /></AppWrapper></PrivateRoute>} />
          <Route path="/add-property" element={<PrivateRoute><AppWrapper><AddHotelPage /></AppWrapper></PrivateRoute>} />
          <Route path="/add-place-id" element={<PrivateRoute><AppWrapper><FindPlaceID /></AppWrapper></PrivateRoute>} />
          <Route path="/p/:id" element={<PrivateRoute><AppWrapper><HotelDetail /></AppWrapper></PrivateRoute>} />
          <Route path="/p/:id/edit" element={<PrivateRoute><AppWrapper><AddHotelPage isEdit={true} /></AppWrapper></PrivateRoute>} />
          <Route path="/p/:id/add-package" element={<PrivateRoute><AppWrapper><AddPackage /></AppWrapper></PrivateRoute>} />
          <Route path="/admin-dashboard" element={<PrivateRoute><AppWrapper><AdminDashboard /></AppWrapper></PrivateRoute>} />
          <Route path="*" element={<LoginPage />} />
        </Routes>
      </Router>
    </Provider>
  );
}

const AnalyticsHandler = () => {
  const location = useLocation();

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'page_view', { page_path: location.pathname });
    }
  }, [location]);

  return null;
};

export default App;
